<template>
  <div>
    <div class="monitorReport" id="pdfRef">
      <div class="cover pdfRef">
        <img class="coverImg" src="../../../assets/monitorReport/cover.png"/>
        <div id="echarts"></div>
        <div class="coverBottom">
          <div>注册代码：{{elevatorInfo.registerNo}}</div>
          <div>监测时间：{{elevatorInfo.monitorStartTime + "~" + elevatorInfo.monitorEndTime}}</div>
          <div>报告时间：{{elevatorInfo.createTime}}</div>
        </div>
      </div>
      <div class="reportContent pdfRef">
        <div class="itemBox">
          <div class="itemTitle">[ 一、电梯基本信息 ]</div>
          <div class="itemContent">
            <div class="itemRow">
              <div class="itemCol">注册代码：{{elevatorInfo.registerNo}}</div>
              <div class="itemCol">使用单位：{{elevatorInfo.useUnit}}</div>
            </div>
            <div class="itemRow">
              <div class="itemCol">地址（项目名称）：{{elevatorInfo.elevatorAddress}}</div>
              <div class="itemCol">维保单位：{{elevatorInfo.maintCom}}</div>
            </div>
            <div class="itemRow">
              <div class="itemCol" v-if="elevatorInfo.reportState">上次按需维保日期：{{elevatorInfo.lastMaintDate}}</div>
              <div class="itemCol" v-else-if="marker">上次按需维保日期：
                <el-date-picker class="editBox" v-model="elevatorInfo.lastMaintDate" value-format="yyyy-MM-dd" type="date"
                                placeholder="选择日期">
                </el-date-picker>
              </div>
              <div class="itemCol" v-else>上次按需维保日期：{{elevatorInfo.lastMaintDate}}</div>
              <div class="itemCol" v-if="elevatorInfo.reportState">按需维保周期（天）：{{elevatorInfo.maintCycle}}</div>
              <div class="itemCol" v-else-if="marker">按需维保周期（天）：
                <el-input class="editBox" style="width: 200px;" placeholder="输入天数"
                          v-model="elevatorInfo.maintCycle"></el-input>
              </div>
              <div class="itemCol" v-else>按需维保周期（天）：{{elevatorInfo.maintCycle}}</div>
            </div>
            <div class="itemRow">
              <div class="itemCol">物联网平台名称：{{elevatorInfo.iotPlatformName}}</div>
            </div>
          </div>
        </div>
        <div class="itemBox">
          <div class="itemTitle">[ 二、周运行统计数据 ]</div>
          <div class="itemContent">
            <div class="itemRow">
              <div class="itemCol">累计运行次数（次）：{{elevatorInfo.runningCount}}</div>
              <div class="itemCol">开关门次数（次）：{{elevatorInfo.doorOpenCloseCount}}</div>
            </div>
            <div class="itemRow">
              <div class="itemCol">累计运行时间（分钟）：{{elevatorInfo.runningTime}}</div>
              <div class="itemCol">物联网终端在线率（百分比）：{{elevatorInfo.onlineRate}}</div>
            </div>
          </div>
        </div>
        <div class="itemBox">
          <div class="itemTitle">[ 三、周故障记录 ]</div>
          <div class="itemContent" v-if="elevatorInfo.faultRecords.length">
            <div class="faultRecords">
              <div style="width: 120px;">原始故障代码</div>
              <div style="flex: 1;">映射故障描述</div>
              <div style="width: 160px;">故障时间</div>
            </div>
            <div v-for="item in elevatorInfo.faultRecords" :key="item.id">
              <div style="display: flex;padding: 10px 0;">
                <div style="width: 120px;">{{item.faultCode}}</div>
                <div style="flex: 1;">{{item.faultDesc}}</div>
                <div style="width: 160px;">{{item.faultTime}}</div>
              </div>
              <div class="reasonList">
                <div class="reasonDesc">故障原因</div>
                <div class="solution">解决方案</div>
              </div>
              <div v-for="each in item.solutionSettings" :key="each.id" class="reasonList" style="border-bottom: none;">
                <div class="reasonDesc">{{each.reason}}</div>
                <div class="solution">{{each.solution}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="itemBox">
          <div class="itemTitle">[ 四、周故障统计 ]</div>
          <div class="itemContent">
            <div class="itemRow">
              <div class="itemCol">故障困人次数（次）：{{elevatorInfo.faultTrappingCount}}</div>
              <div class="itemCol">故障停梯次数（次）：{{elevatorInfo.faultStopCount}}</div>
            </div>
            <div class="itemRow">
              <div class="itemCol">故障停梯时长（分钟）：{{elevatorInfo.faultStopTime}}</div>
              <div class="itemCol">检修时长（分钟）：{{elevatorInfo.overhaulTime}}</div>
            </div>
          </div>
        </div>
        <!-- 五、周困人救援情况 -->
        <div class="itemBox">
          <div class="itemTitle">[ 五、周困人救援情况 ]</div>
          <div class="itemContent" v-if="elevatorInfo.trappedRecords.length">
            <table class="itemTable" cellpadding="0" cellspacing="0">
              <thead>
              <tr>
                <th>事件</th>
                <th>困人发生时间</th>
                <th>困人救援到场时间</th>
                <th>救援用时（分钟）</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in elevatorInfo.trappedRecords" :key="item.id">
                <td>电梯困人</td>
                <td>{{item.alarmTime}}</td>
                <td>{{item.arriveTime}}</td>
                <td>{{item.rescueTime}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- 六、周健康度评价 -->
        <div class="itemBox">
          <div class="itemTitle">[ 六、周健康度评价 ]</div>
          <div class="itemContent">
            <div class="health">
              <div class="healthValue">{{elevatorInfo.healthLevel}}</div>
              <div class="healthDesc">周健康度评价（百分制）</div>
            </div>
          </div>
        </div>
        <!-- 七、保养建议 -->
        <div class="itemBox">
          <div class="itemTitle">[ 七、保养建议 ]</div>
          <div class="itemContent" v-if="faultMaintRecos.length">
            <table class="itemTable" cellpadding="0" cellspacing="0">
              <thead>
              <tr>
                <th style="width: 120px;">原始故障代码</th>
                <th style="width: 260px;">映射故障描述</th>
                <th>保养建议</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in faultMaintRecos" :key="item.id">
                <td>{{item.faultCode}}</td>
                <td style="text-align: left;">{{item.desc}}</td>
                <td style="text-align: left;">{{item.maintReco}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- 八、按需维保期间无纸化维保记录 -->
        <!-- <div class="itemBox">
          <div class="itemTitle">[ 八、按需维保期间无纸化维保记录 ]</div>
          <div class="itemContent" v-if="elevatorInfo.maintWorkOrderVoList.length">
            <table class="itemTable" cellpadding="0" cellspacing="0">
              <thead>
              <tr>
                <th>开始时间</th>
                <th>结束时间</th>
                <th>维保周期</th>
                <th>
                  <div>使用单位</div>
                  是否已签名
                </th>
                <th>维保人员1</th>
                <th>维保人员2</th>
                <th>维保人员3</th>
                <th>维保人员4</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in elevatorInfo.maintWorkOrderVoList" :key="item.id">
                <td>{{item.maintStartTime.slice(0, item.maintStartTime.length - 3)}}</td>
                <td>{{item.maintStartTime.slice(0, item.maintEndTime.length - 3)}}</td>
                <td>
                  <span v-if="item.maintType === 1">半月保</span>
                  <span v-else-if="item.maintType === 2">季度保</span>
                  <span v-else-if="item.maintType === 3">半年保</span>
                  <span v-else-if="item.maintType === 4">年度保</span>
                  <span v-else-if="item.maintType === 5">按需维保</span>
                </td>
                <td>
                  <span v-if="item.unitSign === 1">是</span>
                  <span v-else>否</span>
                </td>
                <td>{{item.maintainerName1}}</td>
                <td>{{item.maintainerName2}}</td>
                <td>{{item.maintainerName3}}</td>
                <td>{{item.maintainerName4}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div> -->
      </div>
      <div class="footer pdfRef">
        <div class="left">
          <div style="font-weight: 700;">说明</div>
          <div style="margin: 6px 0;">本报告由新时达G-CLOUD电梯云平台生成；</div>
          <div>报告中所包含的数据信息，仅代表报告周期内的设备状况。</div>
        </div>
        <div class="right">
          <img class="footerImg" src="../../../assets/monitorReport/gzreportLogo.png"/>
        </div>
      </div>
    </div>
    <!-- 操作按钮 -->
    <div class="btns">
      <div @click="saveHandle">保存</div>
      <div @click="printPdf">下载</div>
    </div>
</div>
</template>

<script>
  import html2Canvas from "html2canvas";
  import jsPDF from "jspdf";

  export default {
    data() {
      return {
        id: "",
        elevatorInfo: {
          id: "",
          registerNo: "",
          faultRecords: [],
          maintWorkOrderVoList: [],   //按需维保期间无纸化维保记录
          trappedRecords: [],
          reportState: "",
          lastMaintDate: "",
          maintCycle: "",
        },
        faultMaintRecos: [],
        autoUpload: "",
        noTableHeight: 0,
        marker: true,
      };
    },

    mounted() {
      this.id = this.$route.params.id;
      this.autoUpload = this.$route.query.autoUpload === "true" ? true : false;
      this.getById();
    },

    methods: {
      // 获取单个数据
      getById() {
        this.$http.get(`gzReport/${this.id}`).then(res => {
          this.elevatorInfo = Object.assign(this.elevatorInfo, res.data);
          this.elevatorInfo.faultRecords.forEach(item => {
            if (item.faultMaintRecos.length) {
              item.faultMaintRecos.forEach(i => {
                i.desc = item.faultDesc;
              });
              this.faultMaintRecos = this.faultMaintRecos.concat(item.faultMaintRecos);
            }
          });
          this.initCharts();
        });
      },

      // 保存
      saveHandle() {
        this.$confirm("确认保存吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          const params = {
            id: this.id,
            lastMaintDate: this.elevatorInfo.lastMaintDate,
            maintCycle: this.elevatorInfo.maintCycle,
          };
          this.$http.put("gzReport", params).then(res => {
            this.$message.success("保存成功！");
          });
        });
      },

      // 打印
      printHandle() {
        window.print();
      },

      printPdf() {
        this.marker = false;
        setTimeout(() => {
          const fileName = `广州监测报告-${this.elevatorInfo.registerNo}-${this.elevatorInfo.monitorStartTime}-${this.elevatorInfo.monitorStartTime}-${new Date().getTime()}`;
          const fileList = document.getElementsByClassName("pdfRef");   // 很重要
          this.htmlPdf(fileName, document.querySelector("#pdfRef"), fileList);
        }, 500);
      },

      htmlPdf(title, html, fileList, type) {
        if (fileList) {
          const pageHeight = Math.floor(277 * html.scrollWidth / 190) + 20;
          for (let i = 0; i < fileList.length; i++) {
            const multiple = Math.ceil((fileList[i].offsetTop + fileList[i].offsetHeight) / pageHeight);
            if (this.isSplit(fileList, i, multiple * pageHeight)) {
              var _H = "";
              if (fileList[i].localName !== "tr") { //判断是不是表格里的内容
                _H = multiple * pageHeight - (fileList[i].offsetTop + fileList[i].offsetHeight);
              } else {
                _H = multiple * pageHeight - (fileList[i].offsetTop + fileList[i].offsetHeight + this.noTableHeight) + 20;
              }
              var newNode = this.getFooterElement(_H);
              const divParent = fileList[i].parentNode;
              const next = fileList[i].nextSibling;
              if (next) {
                divParent.insertBefore(newNode, next);
              } else {
                divParent.appendChild(newNode);
              }
            }
          }
        }

        html2Canvas(html, {
          sacle: 1,
        }).then(canvas => {
          var pdf = new jsPDF("p", "mm", "a4");
          var ctx = canvas.getContext("2d");
          var a4w = type ? 277 : 190;
          var a4h = type ? 190 : 277; // A4大小，210mm x 297mm，四边各保留10mm的边距，显示区域190x277
          var imgHeight = Math.floor(a4h * canvas.width / a4w); // 按A4显示比例换算一页图像的像素高度
          var renderedHeight = 0;
          while (renderedHeight < canvas.height) {
            var page = document.createElement("canvas");
            page.width = canvas.width;
            page.height = Math.min(imgHeight, canvas.height - renderedHeight);// 可能内容不足一页

            // 用getImageData剪裁指定区域，并画到前面创建的canvas对象中
            page.getContext("2d").putImageData(ctx.getImageData(0, renderedHeight, canvas.width, Math.min(imgHeight, canvas.height - renderedHeight)), 0, 0);
            pdf.addImage(page.toDataURL("image/png"), "png", 10, 10, a4w, Math.min(a4h, a4w * page.height / page.width), "", "FAST"); // 添加图像到页面，保留10mm边距
            renderedHeight += imgHeight;
            if (renderedHeight < canvas.height) {
              pdf.addPage();// 如果后面还有内容，添加一个空页
            }
            // delete page;
          }
          // let base64 = pdf.output("datauristring");
          //   let file = this.convertBase64ToFile(base64, title + ".pdf");
          //   this.uploadPdf(file);
          if (this.autoUpload) {
            let base64 = pdf.output("datauristring");
            let file = this.convertBase64ToFile(base64, title + ".pdf");
            this.uploadPdf(file);
          } else {
            pdf.save(title + ".pdf");
          }
        });
      },
      getFooterElement(remainingHeight, fillingHeight = 0) {
        const newNode = document.createElement("div");
        newNode.style.background = "#ffffff";
        newNode.style.width = "calc(100% + 8px)";
        newNode.style.marginLeft = "-4px";
        newNode.style.marginBottom = "0px";
        newNode.classList.add("divRemove");
        newNode.style.height = (remainingHeight + fillingHeight) + "px";
        return newNode;
      },

      isSplit(nodes, index, pageHeight) {
        if (nodes[index].localName !== "tr") {  //判断元素是不是tr
          this.noTableHeight += nodes[index].clientHeight;
        }
        if (nodes[index].localName !== "tr") {
          return nodes[index].offsetTop + nodes[index].offsetHeight < pageHeight && nodes[index + 1] && nodes[index + 1].offsetTop + nodes[index + 1].offsetHeight > pageHeight;
        } else {
          return nodes[index].offsetTop + nodes[index].offsetHeight + this.noTableHeight < pageHeight && nodes[index + 1] && nodes[index + 1].offsetTop + nodes[index + 1].offsetHeight + this.noTableHeight > pageHeight;
        }
      },

      convertBase64ToFile(urlData, filename) {
        var arr = urlData.split("base64,");
        var type = arr[0].match(/:(.*?);/)[1];
        // var fileExt = type.split("/")[1];
        var bstr = atob(arr[1]);
        var n = bstr.length;
        var u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {
          type: type,
        });
      },

      uploadPdf(file) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("id", this.elevatorInfo.id);
        this.$http.post("gzReport/upload/pdf", formData).then(() => {
          window.close();
        });
      },

      initCharts() {
        let myChart = this.$echarts.init(document.getElementById("echarts"));
        let option = {
          legend: {
            orient: "vertical",
            right: "35%",
            bottom: 70,
          },
          color: ["#2B448E", "#B9BDC1"],
          series: [
            {
              type: "pie",
              radius: ["50%", "70%"],
              center: ["37%", "50%"],
              data: [
                {
                  value: this.elevatorInfo.onlineRate,
                  name: "在线",
                },
                {
                  value: 100 - +this.elevatorInfo.onlineRate,
                  name: "不在线",
                },
              ],
              label: {
                color: "#1A1A1A",
                formatter: "{d}%",
              },
            },
          ],
        };
        myChart.setOption(option);
        if (this.autoUpload) {
          setTimeout(() => {
            this.printPdf();
          },5000);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
// 打印样式
@media print {
  .monitorReport {
    width: 21cm;
    margin: 0;
    padding: 0;
  }

  .btns {
    div {
      display: none;
    }
  }
}
.btns {
  width: 60px;
  position: fixed;
  height: 120px;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  
  div {
    height: 60px;
    line-height: 60px;
    text-align: center;
    background-color: #f2f2f2;
    margin-top: 5px;
    border-radius: 5px;
    cursor: pointer;
    color: #2B448E;
    font-size: 14px;
  }
}
// 页面样式
.monitorReport {
  width: 21cm;
  margin: 0 auto;
  color: #1A1A1A;

  .cover {
    height: 29.7cm;
    position: relative;

    // .btns {
    //   width: 180px;
    //   height: 32px;
    //   position: absolute;
    //   top: 16px;
    //   right: 16px;
    //   display: flex;
    //   line-height: 32px;

    //   div {
    //     flex: 1;
    //     background-color: #00C291;
    //     color: #fff;
    //     font-size: 14px;
    //     text-align: center;
    //     border-radius: 8px;
    //     cursor: pointer;
    //   }
    // }

    .coverImg {
      width: 100%;
      height: 100%;
    }

    #echarts {
      position: absolute;
      width: 100%;
      height: 200px;
      bottom: 230px;
    }

    .coverBottom {
      background: #F2F2F2;
      position: absolute;
      bottom: 80px;
      font-size: 16px;
      color: #1A1A1A;
      line-height: 30px;
      width: 270px;
      padding: 20px 50px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .reportContent {
    padding: 0 10px;

    .itemBox {
      .itemTitle {
        line-height: 56px;
        border-bottom: 1px solid #cccccc;
        font-weight: bold;
        font-size: 18px;
        color: #2B448E;
      }
    }

    .itemContent {
      font-size: 14px;

      // 电梯基本信息、周运行统计数据、周故障统计
      .itemRow {
        display: flex;

        .itemCol {
          flex: 1;
          line-height: 36px;
        }
      }

      // 周故障记录
      .faultRecords {
        display: flex;
        padding: 10px 0;
        font-weight: 700;
        border-bottom: 1px solid #ccc;
      }

      .reasonList {
        display: flex;
        padding: 10px;
        border-bottom: 1px solid #ccc;
        background-color: #F2F2F2;

        .reasonDesc {
          width: 300px;
        }

        .solution {
          flex: 1;
          margin-left: 10px;
        }
      }

      // 周困人救援情况、保养建议、按需维保期间无纸化维保记录
      .itemTable {
        width: 100%;

        th {
          border-bottom: 2px solid #0747FD;
          color: #4D4D4D;
          font-weight: 700;
          text-align: left;
          padding: 10px 0;
        }

        td {
          color: #1A1A1A;
          padding: 10px 0;
        }
      }

      // 周健康度评价
      .health {
        width: 300px;
        height: 120px;
        border-radius: 12px;
        background-color: #00C291;
        text-align: center;
        overflow: hidden;
        margin-top: 12px;

        .healthValue {
          font-size: 40px;
          color: #FFFFFF;
          line-height: 80px;
          height: 80px;
        }

        .healthDesc {
          line-height: 40px;
          color: #4D4D4D;
          background-color: #B2EDDE;
        }
      }
    }
  }

  .footer {
    display: flex;
    height: 120px;
    align-items: center;
    padding: 50px 10px;
    font-size: 14px;

    .left {
      flex: 1;
      background-color: #F2F2F2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 16px 0 16px 24px;
    }

    .right {
      margin-left: 46px;

      .footerImg {
        width: 282px;
        height: 72px;
      }
    }
  }
}
</style>
